import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"

import Layout from "../layouts/layout"
import SEO from "../layouts/seo"

const PrivacyPage = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <SEO title={t("footer.link-privacy")} />
      <article class="article legal">
        <h1 class="heading heading--md">Privacy Policy</h1>

        <h3>Datenschutzinformation für die „Collect & Deliver- App“</h3>

        <p>
          der tiramizoo GmbH, vertreten durch den Geschäftsführer Martin Sträb,
          Wilhelm-Hale-Str. 50, 80639 München (nachfolgend „Unternehmen“)
        </p>

        <p>
          Diese Datenschutzinformation informiert Sie über die Verarbeitung
          Ihrer personenbezogenen Daten bei der Nutzung der von dem Unternehmen
          angebotenen „Collect & Deliver-App“ (nachfolgend „C&D-App“).
        </p>

        <p>
          1. 1. Wer ist für die Datenverarbeitung verantwortlich und an wen
          können Sie sich wenden?
        </p>

        <p>
          Datenschutzrechtlich Verantwortlicher für die im Zusammenhang mit der
          Nutzung der C&D-App verarbeiteten personenbezogenen Daten ist die
          tiramizoo GmbH, vertreten durch den Geschäftsführer Martin Sträb,
          Wilhelm-Hale-Str. 50, 80639 München, Telefon +49 89 21 55 68 70,
          E-Mail:&nbsp;
          <a href="mailto:info@tiramizoo.com">info@tiramizoo.com</a>
        </p>

        <p>
          Für alle Fragen zum Thema Datenschutz können Sie sich an unseren
          Datenschutzbeauftragten wenden. Die Kontaktdaten des
          Datenschutzbeauftragten sind:
        </p>

        <p>
          tiramizoo GmbH, Datenschutzbeauftragter, Wilhelm-Hale-Str. 50, 80639
          München, oder&nbsp;
          <a href="mailto:datenschutz@tiramizoo.com">
            datenschutz@tiramizoo.com
          </a>
        </p>

        <p>2. Verarbeitung ihrer Daten</p>

        <p>2.1. Newsletter</p>

        <p>
          Sie haben die Möglichkeit, sich für einen Newsletter zu registrieren.
          In diesem Fall nutzen wir Ihre E-Mail-Adresse zum Versand des
          jeweiligen Newsletters, in dem wir Sie vor allem über Produktneuheiten
          und – weiterentwicklungen informieren werden. Pflichtangabe für die
          Übersendung des Newsletters ist allein Ihre E-Mail-Adresse. Um Ihre
          ordnungsgemäße Anmeldung zum Newsletter sicherzustellen, das heißt, um
          unautorisierte Anmeldungen im Namen Dritter zu verhindern, verwenden
          wir das sogenannte Double-Opt-In-Verfahren. Das heißt, dass wir Ihnen
          nach Ihrer erstmaligen Newsletter Anmeldung eine Bestätigungs-E-Mail
          an die von Ihnen bei der Anmeldung angegebene E-Mail-Adresse zusenden,
          in der wir um Bestätigung Ihrer Registrierung bitten. Wenn Sie Ihre
          Anmeldung nicht innerhalb von drei (3) Tagen bestätigen, werden Ihre
          Informationen gesperrt und nach sieben (7) Tagen automatisch gelöscht.
          Nach Bestätigung Ihrer Registrierung speichern wir Ihre E-Mail-Adresse
          zum Zwecke der Zusendung des Newsletters. Rechtsgrundlage hierfür ist
          Ihre Einwilligung nach Art. 6 Abs. 1 S. 1 lit. a) DSGVO. Ihre
          Einwilligung in die Übersendung des Newsletters können Sie jederzeit
          für die Zukunft widerrufen und den Newsletter abbestellen. Den
          Widerruf können Sie durch Klick auf den in jeder Newsletter-E-Mail
          bereitgestellten Link, per E-Mail an&nbsp;
          <a href="mailto:newsletter@tiramizoo.com">newsletter@tiramizoo.com</a>
          &nbsp; oder durch eine Nachricht an die unter Ziffer 1 angegebene
          Adresse erklären.
        </p>

        <p>
          Im Zusammenhang mit Ihrer Newsletter-Anmeldung speichern wir außerdem
          das Anmeldedatum, die Uhrzeit Ihrer Anmeldung und der Bestätigung
          sowie ihre Sprach- und Zeitzoneneinstellung. Zweck der Speicherung
          ist, dass wir zu einem späteren Zeitpunkt die Anmeldung nachvollziehen
          und beweisen können und um ggf. einen möglichen Missbrauch Ihrer
          persönlichen Daten aufklären zu können. Rechtsgrundlage dafür ist
          unser berechtigtes Interesse im Sinne Art. 6 Abs. 1 S. 1 lit. f) DSGVO
          (berechtigtes Interesse), wobei das berechtigte Interesse in der
          Nachweismöglichkeit der Anmeldung liegt. Wenn Sie der Speicherung der
          vorgenannten Daten widersprechen möchten, wenden Sie sich bitte an
          &nbsp;
          <a href="mailto:newsletter@tiramizoo.com">newsletter@tiramizoo.com</a>
        </p>

        <p>2.2. Feedback</p>

        <p>
          Sie haben zudem die Möglichkeit, uns Feedback zu der C&D-App entweder
          per E-Mail oder über ihr mobiles Endgerät (z.B. Smartphone oder
          Tablet) zu übermitteln. In diesem Fall speichern wir Ihre
          E-Mail-Adresse und/oder Ihre Mobiltelefonnummer sowie den Inhalt Ihrer
          Nachricht auf unserem Server. Angaben, die Sie uns im Rahmen Ihres
          Feedbacks mitteilen, verwenden wir auf Grundlage von Art. 6 Abs. 1 S.
          1 lit. b) DSGVO (Vertragserfüllung und vorvertragliche Maßnahmen) bzw.
          Art. 6 Abs. 1 S. 1 lit. f) DSGVO (berechtigtes Interesse). Unser
          berechtigtes unternehmerisches Interesse besteht darin, durch das von
          Ihnen gegebene Feedback die von uns angebotene C&D-App stetig
          weiterzuentwickeln und zu verbessern. Ihre im Zusammenhang mit dem
          Feedback verarbeitete Daten werden nur zur Bearbeitung Ihres Feedbacks
          gespeichert und anschließend unverzüglich gelöscht, soweit keine
          gesetzlichen Aufbewahrungsfristen unsererseits bestehen. Eine
          Weitergabe dieser Daten an Dritte findet nicht statt.
        </p>

        <p>2.3. etracker – Analyseprogramm</p>

        <p>
          Wir verwenden Dienste der etracker GmbH zur Analyse von Nutzungsdaten,
          um die App zu verbessern und Fehler, wie z.B. Fehlverhalten oder
          Abstürze zu analysieren und zu vermeiden. Es werden dabei Cookies
          eingesetzt, die eine statistische Analyse der Nutzung der App durch
          ihre Nutzer sowie Erfolgsmessung unserer Online-Marketing-Maßnahmen
          sowie Testverfahren ermöglichen. etracker Cookies enthalten keine
          Informationen, die eine Identifikation eines Nutzers ermöglichen. Die
          Datenverarbeitung erfolgt auf der Rechtsgrundlage des Art. 6 Abs. 1
          lit. f DSGVO. Unser berechtigtes Interesse besteht in der Optimierung
          unserer App. Da uns die Privatsphäre unserer Besucher besonders
          wichtig ist, wird die IP-Adresse bei etracker frühestmöglich
          anonymisiert und Anmelde- oder Gerätekennungen bei etracker zu einem
          eindeutigen, aber nicht einer Person zugeordneten Schlüssel
          umgewandelt. Eine andere Verwendung, Zusammenführung mit anderen Daten
          oder eine Weitergabe an Dritte erfolgt durch etracker nicht.
        </p>

        <p>
          Sie können der vorbeschriebenen Datenverarbeitung jederzeit über den
          folgenden Link widersprechen, soweit sie personenbezogen erfolgt. Ihr
          Widerspruch hat für Sie keine nachteiligen Folgen:&nbsp;
          <a href="http://www.etracker.de/privacy?et=PEgGdE">
            www.etracker.de/privacy?et=PEgGdE
          </a>
        </p>

        <p>
          Dadurch wird ein Opt-Out-Cookie mit dem Namen „cntcookie“ von etracker
          gesetzt. Bitte löschen Sie dieses Cookie nicht, solange Sie Ihren
          Widerspruch aufrechterhalten möchten. Weitere Informationen finden Sie
          in den Datenschutzbestimmungen von etracker:&nbsp;
          <a href="http://www.etracker.com/de/datenschutz.html">
            www.etracker.com/de/datenschutz.html
          </a>
        </p>

        <p>3. Weitergabe von Daten</p>

        <p>
          Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als
          den im Folgenden aufgeführten Zwecken findet nicht statt.
        </p>

        <p>Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:</p>

        <ul>
          <li>
            Sie Ihre nach Art. 6 Abs. 1 S. 1 lit. a) DSGVO ausdrückliche
            Einwilligung dazu erteilt haben,
          </li>
          <li>
            die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f) DSGVO zur
            Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
            erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein
            überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer
            Daten haben,
          </li>
          <li>
            für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c) DSGVO eine
            gesetzliche Verpflichtung besteht, sowie
          </li>
          <li>
            dies gesetzlich zulässig und nach Art. 6 Abs. 1 S. 1 lit. b) DSGVO
            für die Abwicklung von Vertragsverhältnissen mit Ihnen erforderlich
            ist.
          </li>
        </ul>

        <p>4. Dauer der Aufbewahrung Ihrer personenbezogenen Daten</p>

        <p>
          Wir speichern und verarbeiten Ihre Daten, wenn nicht in dieser
          Information anders angegeben, solange und soweit dies für die
          Geschäftsbeziehung mit Ihnen erforderlich ist. Wir beachten den
          Grundsatz der Speicherbegrenzung, d.h., dass wir Ihre Daten löschen,
          sobald wir sie nicht mehr benötigen und auch gesetzlich nicht zur
          Aufbewahrung verpflichtet sind.
        </p>

        <p>5. Datenübermittlung an ein Drittland</p>

        <p>
          Eine Übermittlung in ein Drittland findet grundsätzlich nicht statt,
          es sei denn, es ist in dieser Datenschutzinformation ausdrücklich
          erwähnt.
        </p>

        <p>6. Welche Rechte haben Sie?</p>

        <p>
          Sie haben gegenüber uns folgende Rechte hinsichtlich der Sie
          betreffenden personenbezogenen Daten:
        </p>

        <ul>
          <li>
            Recht auf Auskunft gemäß Art. 15 DSGVO: Sie haben das Recht eine
            Bestätigung darüber zu verlangen, ob Sie betreffende
            personenbezogene Daten verarbeitet werden; ist dies der Fall, so
            haben Sie ein Recht auf Auskunft über diese personenbezogenen Daten
            und auf die in Art. 15 DSGVO im Einzelnen aufgeführten
            Informationen.
          </li>
          <li>
            Recht auf Berichtigung unrichtiger personenbezogener Daten oder auf
            Vervollständigung gemäß Art. 16 DSGVO: Sie haben das Recht,
            unverzüglich die Berichtigung Sie betreffender unrichtiger
            personenbezogener Daten und ggf. die Vervollständigung
            unvollständiger personenbezogener Daten zu verlangen.
          </li>
          <li>
            Recht auf Löschung ("Recht auf Vergessenwerden") gemäß Art. 17
            DSGVO: Sie haben zudem das Recht, zu verlangen, dass Sie betreffende
            personenbezogene Daten unverzüglich gelöscht werden, sofern einer
            der in Art. 17 DSGVO im einzelnen aufgeführten Gründe zutrifft, z.
            B. wenn die Daten für die verfolgten Zwecke nicht mehr benötigt
            werden.
          </li>
          <li>
            Recht auf Einschränkung der Verarbeitung gemäß Art. 18 DSGVO: Sie
            haben das Recht, die Einschränkung der Verarbeitung zu verlangen,
            wenn eine der in Art. 18 DSGVO aufgeführten Voraussetzungen gegeben
            ist, z. B. wenn Sie Widerspruch gemäß Art. 21 DSGVO gegen die
            Verarbeitung eingelegt haben, für die Dauer einer etwaigen Prüfung,
            ob unsere berechtigten Interessen gegenüber den Ihren überwiegen.
          </li>
          <li>
            <strong>
              Recht auf Widerspruch gegen die Verarbeitung gemäß Art. 21 DSGVO:
              Werden Daten auf Grundlage von Art. 6 Abs. 1 S. 1 lit. f DSGVO
              erhoben (Datenverarbeitung zur Wahrung berechtigter Interessen),
              steht Ihnen das Recht zu, aus Gründen, die sich aus Ihrer
              besonderen Situation ergeben, jederzeit gegen die Verarbeitung
              Widerspruch einzulegen. Wir verarbeiten die personenbezogenen
              Daten dann nicht mehr, es sei denn, es liegen nachweisbar
              zwingende schutzwürdige Gründe für die Verarbeitung vor, die Ihren
              Interessen, Rechten und Freiheiten gegenüber überwiegen, oder die
              Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung
              von Rechtsansprüchen.
            </strong>
          </li>
          <li>
            Recht auf Datenübertragbarkeit gemäß Art. 20 DSGVO: In bestimmten
            Fällen, die in Art. 20 DSGVO im Einzelnen aufgeführt werden, haben
            Sie das Recht, die Sie betreffenden personenbezogenen Daten in einem
            strukturierten, gängigen und maschinenlesbaren Format zu erhalten
            bzw. die Übermittlung dieser Daten an einen Dritten zu verlangen.
          </li>
          <li>
            Recht auf Widerruf Ihrer erteilten Einwilligung gemäß Art. 7 Abs. 3
            S. 1 DSGVO: Sie haben das Recht eine erteilte Einwilligung jederzeit
            mit Wirkung für die Zukunft zu widerrufen, ohne dass die
            Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf
            erfolgten Verarbeitung berührt wird.
          </li>
          <li>
            Beschwerderecht bei einer Aufsichtsbehörde gem. Art. 77 DSGVO: Sie
            haben gemäß Art. 77 DSGVO das Recht auf Beschwerde bei einer
            Aufsichtsbehörde, wenn Sie der Ansicht sind, dass die Verarbeitung
            der Sie betreffenden Daten gegen datenschutzrechtliche Bestimmungen
            verstößt. Das Beschwerderecht kann insbesondere bei einer
            Aufsichtsbehörde in dem Mitgliedstaat Ihres Aufenthaltsorts, Ihres
            Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes geltend
            gemacht werden.
          </li>
        </ul>

        <p>
          Sofern Sie von Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch machen
          möchten, genügt eine E-Mail an&nbsp;
          <a hreg="mailto:support@tiramizoo.com">support@tiramizoo.com</a>
        </p>
        <p>Stand April 2022</p>
      </article>
    </Layout>
  )
}
export default PrivacyPage
